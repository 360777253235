import "./App.scss";
import { Canvas } from "@react-three/fiber";
import { Html, MapControls, useGLTF } from "@react-three/drei";
import { Suspense, useState } from "react";

function Model() {
  const gltf = useGLTF("/asbeck_reduced.glb");
  return <primitive object={gltf.scene} />;
}

function App() {
  const [open, setOpen] = useState(null);
  return (
    <div className="App">
      <Suspense fallback={<div className="loadingScreen">loading ...</div>}>
        <Canvas
          orthographic
          shadows
          camera={{ position: [15, 20, 15], zoom: 3, near: -1000 }}
          onCreated={(gl) => gl.gl.setClearColor("#222")}
        >
          <group position={[50, 0, -25]}>
            <Model></Model>
            <InfoDot
              overlay={2}
              open={open === 2}
              setOpen={(v) => setOpen(v)}
              position={[-29, 5, 55]}
            ></InfoDot>
            <InfoDot
              overlay={3}
              open={open === 3}
              setOpen={(v) => setOpen(v)}
              position={[7, 5, -30]}
            ></InfoDot>
            <InfoDot
              overlay={1}
              open={open === 1}
              setOpen={(v) => setOpen(v)}
              position={[-40, 5, -18]}
            ></InfoDot>
          </group>
          <ambientLight></ambientLight>
          <MapControls minPolarAngle={0} maxPolarAngle={1.4}></MapControls>
        </Canvas>
      </Suspense>
      {open && (
        <div className="mobileInfoView">
          <img alt="info" src={"/t" + open + ".png"}></img>
          <div className="closeButton" onClick={() => setOpen(null)}>
            X
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

function InfoDot({ position, overlay, open, setOpen }) {
  return (
    <group>
      <mesh position={position}>
        <Html>
          <div
            className={"ibutton " + (open ? "open" : "")}
            onClick={() => setOpen(open ? null : overlay)}
          >
            i
          </div>
          {false && (
            <div className="overlay" onClick={() => setOpen(null)}>
              <img alt="info" src={"/t" + overlay + ".png"}></img>
            </div>
          )}
        </Html>
      </mesh>
    </group>
  );
}
